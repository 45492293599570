import React                from "react";
import PropTypes            from "prop-types";
import Navigate             from "Dashboard/Core/Navigate";

// Components
import CommercialHeader     from "./Commercial/CommercialHeader";
import CommercialContent    from "./Commercial/CommercialContent";
import OperationsContent    from "./Operations/OperationsContent";
import OperationsHeader     from "./Operations/OperationsHeader";
import MapHeader            from "./Map/MapHeader";
import MapContent           from "./Map/MapContent";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";



/**
 * The Dashboard Supervisor Page
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function DashboardSupervisorPage(props) {
    const { type } = props;
    const { dashboardTab } = Navigate.useParams();
    const navigate = Navigate.useGoto();


    // Load the Data
    React.useEffect(() => {
        if (!dashboardTab) {
            navigate("DASHBOARD", "COMMERCIAL");
        }
    }, [ dashboardTab ]);

    // Handles the Tab
    const handleTab = (url) => {
        navigate("DASHBOARD", url);
    };


    // Variables
    const isCommercial = Navigate.isUrl("COMMERCIAL", dashboardTab);
    const isOperations = Navigate.isUrl("OPERATIONS", dashboardTab);
    const isMap        = Navigate.isUrl("MAP",        dashboardTab);


    // Do the Render
    return <Main>
        <Header message="DASHBOARD_NAME" icon="home">
            <CommercialHeader isHidden={!isCommercial} type={type} />
            <OperationsHeader isHidden={!isOperations} type={type} />
            <MapHeader isHidden={!isMap} type={type} />
        </Header>

        <TabList
            className="left-space-big"
            selected={dashboardTab}
            onClick={handleTab}
        >
            <TabItem
                message="DASHBOARD_COMMERCIAL"
                url="COMMERCIAL"
            />
            <TabItem
                message="DASHBOARD_OPERATIONS"
                url="OPERATIONS"
            />
            <TabItem
                message="GENERAL_MAP"
                url="MAP"
            />
        </TabList>

        <Content>
            <CommercialContent isHidden={!isCommercial} type={type} />
            <OperationsContent isHidden={!isOperations} type={type} />
            <MapContent isHidden={!isMap} />
        </Content>
    </Main>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
DashboardSupervisorPage.propTypes = {
    type : PropTypes.string.isRequired,
};

export default DashboardSupervisorPage;
